<template>
  <div id="app">
    <div class="index-container" @click.stop="playMusic">
      <div class="body-background">
        <div class="content-item">
          <div class="center-earth" style="">
            <div class="avatar" v-if="showAvatar">
              <img :src="avatar" alt="">
            </div>
          </div>
          <div class="center-inner rotating-element2"></div>
          <div class="center-outer rotating-element"></div>
          <div class="day">{{dayText}}</div>
          <div class="title1">个人业绩月排名TOP20</div>
<!--          <div class="title2">个人业绩月排名TOP20</div>-->
          <div class="title3"> 个人业绩周排名TOP10</div>
          <div class="title4">个人业绩日排名TOP10</div>
          <div class="title5">部门全款率月排名</div>
          <div class="item1">
            <div class="canvas" id="canvas1"></div>
          </div>
          <div class="item2" v-if="0">
            <div class="canvas" id="canvas2"></div>
          </div>
          <div class="item3">
            <div class="canvas" id="canvas3"></div>
          </div>
          <div class="item4">
            <div class="canvas" id="canvas4"></div>
          </div>
          <div class="item5">
            <div class="canvas" id="canvas5"></div>
          </div>
        </div>
      </div>
    </div>
    <audio id="music" :src="musicUrl" preload="auto" muted @ended="handleAudioEnded"></audio>

  </div>
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'App',
  components: {

  },
  data(){
    return{
      musicUrl:'',
      showAvatar:false,
      avatar:'',
      dayText:'',
      ispay:false,
      timer:null,
      socketStatus:'',
      interval:null,
      client_id:'',
      ws:null,
      myChart1:null,
      myChart2:null,
      myChart3:null,
      myChart4:null,
      myChart5:null,
      colorList:[
        '#FF7340',
        "#FFA940",
        "#FFE240",
        "#E6FF40",
        "#B9FF40",
        "#80FF40",
        "#40FFA9",
        "#40CCFF",
        "#4050FF",
        "#A640FF",
        '#FF7340',
        "#FFA940",
        "#FFE240",
        "#E6FF40",
        "#B9FF40",
        "#80FF40",
        "#40FFA9",
        "#40CCFF",
        "#4050FF",
        "#A640FF"
      ]
    }
  },
  created(){
     this.initSocket()
  },
  mounted(){
    this.getDay()
    this.getData1()
   // this.getData2()
    this.getData3()
    this.getData4()
    this.getData5()
  },
  methods: {
    handleAudioEnded() {
      console.log('音频播放完成');
      this.avatar='';
      this.showAvatar=false
      // 在这里执行播放完成后的操作，例如重新设置音频、显示消息等。
    },

    getUrlQueryString(names, urls){
      urls = urls || window.location.href;
      urls && urls.indexOf("?") > -1
          ? (urls = urls.substring(urls.indexOf("?") + 1))
          : "";
      var reg = new RegExp("(^|&)" + names + "=([^&]*)(&|$)", "i");
      var r = urls ? urls.match(reg) : window.location.search.substr(1).match(reg);
      if (r != null && r[2] != "") return unescape(r[2]);
      return null;
    },
    initChat() {
      let id=this.getUrlQueryString('brand_id');
      let params = {
        client_id: this.client_id,
        brand_id: id?id:'bef'
      }
      this.$api.getInit(params);

    },
    getDay() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        const now = new Date();
        var year = now.getFullYear(); // 获取年份
        var month = now.getMonth() + 1; // 获取月份，月份是从0开始计算，因此需要 +1
        var date = now.getDate(); // 获取日期
        var day = now.getDay();
        var hours = now.getHours();
        var minutes = now.getMinutes();
        var seconds = now.getSeconds();
        var week = ''
        if (month < 10) {
          month = '0' + month
        }
        if (date < 10) {
          date = '0' + date
        }
        if (hours < 10) {
          hours = '0' + hours
        }
        if (minutes < 10) {
          minutes = '0' + minutes
        }
        if (seconds < 10) {
          seconds = '0' + seconds
        }
        if (day == 0) {
          week = '星期日'
        } else if (day == 1) {
          week = '星期一'
        } else if (day == 2) {
          week = '星期二'
        } else if (day == 3) {
          week = '星期三'
        } else if (day == 4) {
          week = '星期四'
        } else if (day == 5) {
          week = '星期五'
        } else {
          week = '星期六'
        }

        this.dayText = year + '年' + month + '月' + date + '日 ' + week + ' ' + hours + ':' + minutes + ':' + seconds
        // //console.log(this.dayText)
      }, 1000)

    },
    transformFontSize(fontsize) {
      // 获取屏幕宽度
      const width = document.body.scrollWidth;
      const ratio = width / 1920;
      return parseInt(fontsize * ratio);
    },
    getData1(x,y) {
      let chatDom = document.getElementById('canvas1')
      var myChart = echarts.init(chatDom)
     // const data = [];
      // for (let i = 0; i < 10; ++i) {
      //   data.push(Math.round(Math.random() * 200));
      // }
      let option = {
        xAxis: {
         // max: 'dataMax',
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
        },
        yAxis: {
          type: 'category',
          data: x,
          axisTick: {show: false},
          axisLabel: {
            show: true,
            fontSize:this.transformFontSize(16)
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
          //max: 10 // only the largest 3 bars will be displayed
        },
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            data: y,
            itemStyle:{
              color:(param)=> {
                ////console.log(param)
                return this.colorList[param.dataIndex]
              }
            },
            label: {
              show: false,
              position: 'right',
              valueAnimation: true
            },
             //barWidth: '80%', // 控制柱子宽度，影响间距
             //barCategoryGap: '20%', // 类目间隔，类目间的间隔
             //barGap: '50%' // 柱子间的间隔
          }
        ],
        legend: {
          show: false
        },
        grid: {
          containLabel: true, // 确保所有的标签都在格网里面
          top: 1,
          left:1,
          bottom: 0,
          borderWidth: 0,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      };

      // for (var i = 0; i < data.length; ++i) {
      //   if (Math.random() > 0.9) {
      //     data[i] += Math.round(Math.random() * 2000);
      //   } else {
      //     data[i] += Math.round(Math.random() * 200);
      //   }
      //
      // }
      // //console.log(data)
      // myChart.setOption({
      //   series: [
      //     {
      //       type: 'bar',
      //       data
      //     }
      //   ]
      // });
      myChart.setOption(option);
      this.myChart1=myChart
    },
    setData1(x,y){
       this.myChart1.setOption({
          yAxis: {
          data: x,
        },
        series: [
        {
       data: y,
           // itemStyle:{
           //   color:(param)=> {
           //  ////console.log(param)
           //     return this.colorList[param.dataIndex]
           //    }
           // },
        }
        ],
      })
    },
    getData2(x,y) {
      let chatDom = document.getElementById('canvas2')
      var myChart = echarts.init(chatDom)
      let option = {
        xAxis: {
          max: 'dataMax',
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
        },
        yAxis: {
          type: 'category',
          data: x,
          axisTick: {show: false},
          axisLabel: {
            show: true,
            fontSize:this.transformFontSize(16)
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          inverse: true,
          animationDuration: 300,
          animationDurationUpdate: 300,
         // max: 10 // only the largest 3 bars will be displayed
        },
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            data: y,
            itemStyle:{
              color:(param)=> {
                ////console.log(param)
                return this.colorList[param.dataIndex]
              }
            },
            label: {
              show: false,
              position: 'right',
              valueAnimation: true
            }
          }
        ],
        legend: {
          show: false
        },
        grid: {
          containLabel: true,
          top: 1,
          bottom: 0,
          left:2,
          borderWidth: 0,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      };
      // for (var i = 0; i < data.length; ++i) {
      //   if (Math.random() > 0.9) {
      //     data[i] += Math.round(Math.random() * 2000);
      //   } else {
      //     data[i] += Math.round(Math.random() * 200);
      //   }
      //
      // }
      // //console.log(data)
      // myChart.setOption({
      //   series: [
      //     {
      //       type: 'bar',
      //       data
      //     }
      //   ]
      // });
      myChart.setOption(option);
      this.myChart2=myChart
    },
    setData2(x,y){
      this.myChart2.setOption({
        yAxis: {
          data: x,
        },
        series: [
          {
            data: y,
            // itemStyle:{
            //   color:(param)=> {
            //  ////console.log(param)
            //     return this.colorList[param.dataIndex]
            //    }
            // },
          }
        ],
      })
    },
    setData3(x,y){
      this.myChart3.setOption({
        xAxis: {
          data: x,
        },
        series: [
          {
            data: y,
            // itemStyle:{
            //   color:(param)=> {
            //  ////console.log(param)
            //     return this.colorList[param.dataIndex]
            //    }
            // },
          }
        ],
      })
    },
    setData4(x,y){
      this.myChart4.setOption({
        xAxis: {
          data: x,
        },
        series: [
          {
            data: y,
            // itemStyle:{
            //   color:(param)=> {
            //  ////console.log(param)
            //     return this.colorList[param.dataIndex]
            //    }
            // },
          }
        ],
      })
    },
    setData5(x,y){
      this.myChart5.setOption({
        xAxis: {
          data: x,
        },
        series: [
          {
            data: y,
            // itemStyle:{
            //   color:(param)=> {
            //  ////console.log(param)
            //     return this.colorList[param.dataIndex]
            //    }
            // },
          }
        ],
      })
    },
    getData3(x,y) {
      //let that=this;
      let chatDom = document.getElementById('canvas3')
      var myChart = echarts.init(chatDom)
      const data = [];
      for (let i = 0; i < 10; ++i) {
        data.push(Math.round(Math.random() * 200));
      }
      let option = {
        xAxis: {
          type: 'category',
          //  max: 'dataMax',
          axisLabel: {
            show: true,
            interval: 0,
            fontSize:this.transformFontSize(16)
          },
          data: x,

          axisTick: {show: false},
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          animationDuration: 300,
          animationDurationUpdate: 300,
          //max: 10 // only the largest 3 bars will be displayed
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: false
          },
          splitLine: {
            show: false
          },
          axisTick: {show: false},
          axisLine: {
            show: false
          },
          //inverse: true,

        },
        label:{
          fontSize:10
        },
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            data: y,
            itemStyle:{
              fontSize: 10,
              color:(param)=> {
                ////console.log(param)
                return this.colorList[param.dataIndex]
              }
            },
            barWidth:'auto',
            label: {
              show: false,
              position: 'right',
              valueAnimation: true
            },
            labelLine: {
              show: false
            }
          }
        ],
        legend: {
          show: false
        },
        grid: {
          containLabel: true,
          show: false,
          top: 1,
          bottom: 20,
          left: 1,
          right: 0,
          borderWidth: 0,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      };
      // for (var i = 0; i < data.length; ++i) {
      //   if (Math.random() > 0.9) {
      //     data[i] += Math.round(Math.random() * 2000);
      //   } else {
      //     data[i] += Math.round(Math.random() * 200);
      //   }
      //
      // }
      // //console.log(data)
      // myChart.setOption({
      //   series: [
      //     {
      //       type: 'bar',
      //       data
      //     }
      //   ]
      // });
      myChart.setOption(option);
      this.myChart3=myChart
    },
    getData4(x,y) {
      let chatDom = document.getElementById('canvas4')
      var myChart = echarts.init(chatDom)
      const data = [];
      for (let i = 0; i < 10; ++i) {
        data.push(Math.round(Math.random() * 200));
      }
      let option = {
        xAxis: {
          type: 'category',
          //  max: 'dataMax',
          axisLabel: {
            show: true,
            interval: 0,
            fontSize:this.transformFontSize(16)
          },
          data: x,
          axisTick: {show: false},
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          animationDuration: 300,
          animationDurationUpdate: 300,
         // max: 10 // only the largest 3 bars will be displayed
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: false
          },
          axisTick: {show: false},
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          //inverse: true,

        },
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            data: y,
            itemStyle:{
              color:(param)=> {
                ////console.log(param)
                return this.colorList[param.dataIndex]
              }
            },
            label: {
              show: false,
              position: 'right',
              valueAnimation: true
            }
          }
        ],
        legend: {
          show: false
        },
        grid: {
          containLabel: true,
          top: 1,
          bottom: 20,
          left: 3,
          right: 1,
          borderWidth: 0,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      };
      // for (var i = 0; i < data.length; ++i) {
      //   if (Math.random() > 0.9) {
      //     data[i] += Math.round(Math.random() * 2000);
      //   } else {
      //     data[i] += Math.round(Math.random() * 200);
      //   }
      //
      // }
      // //console.log(data)
      // myChart.setOption({
      //   series: [
      //     {
      //       type: 'bar',
      //       data
      //     }
      //   ]
      // });
      myChart.setOption(option);
      this.myChart4=myChart
    },
    getData5(x,y) {
      let chatDom = document.getElementById('canvas5')
      var myChart = echarts.init(chatDom)
      const data = [];
      for (let i = 0; i < 10; ++i) {
        data.push(Math.round(Math.random() * 200));
      }
      let option = {
        xAxis: {
          type: 'category',
          // max:'dataMax',
          axisLabel: {
            show: true,
            fontSize:this.transformFontSize(16)
          },
          data: x,
          axisTick: {show: false},
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          animationDuration: 300,
          animationDurationUpdate: 300,
          //max: 10 // only the largest 3 bars will be displayed
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: false
          },
          axisTick: {show: false},
          axisLine: {
            show: false
          },
          splitLine: {
            show: false
          },
          //inverse: true,

        },
        series: [
          {
            realtimeSort: true,
            type: 'bar',
            data: y,
            itemStyle:{
              color:(param)=> {
                ////console.log(param)
                return this.colorList[param.dataIndex]
              }
            },
            label: {
              show: false,
              position: 'right',
              valueAnimation: true
            }
          }
        ],
        legend: {
          show: false
        },
        grid: {
          containLabel: true,
          top: 1,
          bottom: 20,
          left:0,
          right:0,
          borderWidth: 0,
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      };
      // for (var i = 0; i < data.length; ++i) {
      //   if (Math.random() > 0.9) {
      //     data[i] += Math.round(Math.random() * 2000);
      //   } else {
      //     data[i] += Math.round(Math.random() * 200);
      //   }
      //
      // }
      // //console.log(data)
      // myChart.setOption({
      //   series: [
      //     {
      //       type: 'bar',
      //       data
      //     }
      //   ]
      // });
      myChart.setOption(option);
      this.myChart5=myChart
    },
    initSocket() {
      this.ws = new WebSocket(window.wssUrl + '/wss');
      this.ws.onopen = this.handleWsOpen
      this.ws.onclose = this.handleWsClose
      this.ws.onerror = this.handleWsError
      this.ws.onmessage = this.handleWsMessage
    },
    handleWsOpen() {
      //console.log('WebSocket2已经打开: ')
      this.socketStatus = 'connected'
        this.times()
    },
    handleWsMessage(e) {
      //let that=this;
      var obj = JSON.parse(e.data);
      //console.log(obj)
      if (obj.ws_type == 'connect') {
        this.client_id = obj.client_id;
     //   this.initChat()
      }
      if(obj.data&&obj.ws_type=='data'){
        let data=obj.data;
      //  month_rank_above  这个是左边上边的。month_rank_below这个是左边下面的
       // day_rank 这个是右下
        //week_rank  这个是 右上
        //department_month_rank  这个是中间的
        if (data.day_rank){
          let data1=this.setXlineData(data.day_rank)
          let data2=this.setYlineData(data.day_rank)
          if (this.socketStatus === 'connected') {
            this.setData4(data1,data2)
          }else{
            this.getData4(data1,data2)
          }

         // //console.log(data.day_rank)
        }
        if (data.month_rank){
          let data1=this.setXlineData(data.month_rank)
          let data2=this.setYlineData(data.month_rank)
          if (this.socketStatus === 'connected') {
            this.setData1(data1,data2)
          }else{
            this.getData1(data1,data2)
          }

          // //console.log(data.day_rank)
        }
        // if (data.month_rank_below){
        //   let data1=this.setXlineData(data.month_rank_below)
        //   let data2=this.setYlineData(data.month_rank_below)
        //   if (this.socketStatus === 'connected') {
        //     this.setData2(data1,data2)
        //   }else{
        //     this.getData2(data1,data2)
        //   }
        //
        //   // //console.log(data.day_rank)
        // }
        if (data.week_rank){
          let data1=this.setXlineData(data.week_rank)
          let data2=this.setYlineData(data.week_rank)
          if (this.socketStatus === 'connected') {
            this.setData3(data1,data2)
          }else{
            this.getData3(data1,data2)
          }

          // //console.log(data.day_rank)
        }
        if (data.department_month_rank){
          let data1=this.setXlineData(data.department_month_rank)
          let data2=this.setYlineData(data.department_month_rank)
          if (this.socketStatus === 'connected') {
            this.setData5(data1,data2)
          }else{
            this.getData5(data1,data2)
          }


        }

      }

      if(obj.data&&obj.ws_type=='audio'){
         this.musicUrl=obj.data;
         this.avatar=obj.avatar;
         this.showAvatar=true;
        let audioId=document.getElementById('music');

        setTimeout(()=>{
          audioId.play();
         // const audio = new Audio(this.musicUrl);
// 检查浏览器是否支持自动播放
//           if (audio.play()) {
//             // 如果支持，则直接播放
//             audio.play().then(() => {
//                   console.log('音频播放开始');
//                 }).catch(error => {
//                   console.log('播放音频出现错误:', error);
//                 });
//           }

        },100)
      }
      // return
    },
    playMusic(){
      if (!this.ispay){
        let audioId=document.getElementById('music');
        audioId.play();
      }

       this.ispay=true
    },
    setXlineData(data){
      let list=[];
      data.forEach(item=>{

        list.push(item.name)
      })
      return list
    },
    setYlineData(data){
      let list=[];
      data.forEach(item=>{

        list.push(item.number)
      })
      return list
    },
    handleWsClose() {
      //console.log('WebSocket2关闭: ')
      this.initSocket()

    },
    handleWsError() {
      //console.log('WebSocket2发生错误: ')

    },
    times() {
      clearInterval(this.interval)
      this.interval = setInterval(() => {
        if (this.socketStatus === 'connected') {
          this.ws.send('ping')
        }
      }, 30000)
    },
  }
}
</script>

<style lang="scss">
body{
  margin:0;
  color: #fff
}
@media screen and (max-width: 375px) {
  body {
    font-size: 4px;
  }
}
@media screen and (min-width: 376px) and (max-width: 768px) {
  body {
    font-size: 6px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  body {
    font-size: 10px;
  }
}
@media screen and (min-width: 1025px) {
  body {
    font-size: 16px;
  }
}
#app {
  .index-container {
    height: 100vh;
    width: 100vw;
    background-color: #0f0b34;
    .body-background {
      background-image: url('./assets/images/main-bg.png');
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      margin: 0 auto;
      padding-bottom: 56.25%;
      .content-item{

        .center-earth{
          position: relative;
          width: 30vw;
          height: 30vw;
          top: 7vw;
          left:0;
          right: 0;
          margin: 0 auto;
          background-image: url('./assets/images/center-earth.png');
          .avatar{
            position: absolute;
            top: 0;
            right:0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }

        .center-earth,.center-inner {
          position: absolute;
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat
        }

        .center-inner {
          width: 30vw;
          height: 30vw;
          top: 7vw;
          left:0;
          right: 0;
          margin: 0 auto;
          background-image: url('./assets/images/center-inner.png')
        }

        .center-outer {
          width: 30vw;
          height: 30vw;
          top: 7vw;
          left:0;
          right: 0;
          margin: 0 auto;
          position: absolute;
          background-image: url('./assets/images/center-outer.png');
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat
        }

        .broadcast-item{
          text-align: center;
          position: absolute;
          color: #fff;
          z-index: 999
        }
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .rotating-element {
          animation: rotate 30s linear infinite;
        }
        @keyframes rotate2 {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(-360deg);
          }
        }

        .rotating-element2 {
          animation: rotate2 30s linear infinite;
        }
        .day{
           position: absolute;
          top: 3vw;
          left: 4vw;
          font-weight: 700;
          color: #51b7e3;
        }
        .title1{
          position: absolute;
          top: 7vw;
          left: 4.5vw;
        }
        .title2{
          position: absolute;
          top: 29.2vw;
          left: 4.5vw;
        }
        .title3{
          position: absolute;
          top: 6.8vw;
          right: 13.5vw;
        }
        .title4{
          position: absolute;
          top: 28.3vw;
          right: 15.2vw;
        }
        .title5{
          position: absolute;
          top: 39vw;
          left: 31vw;
        }
        .item1{
          position: absolute;
          top: 9.8vw;
          left: 4.5vw;
          .canvas{
            height: 40vw;
            width: 24vw;
            //background-color: #fff;
          }
        }
        .item2{
          position: absolute;
          top: 32vw;
          left: 4.5vw;
          .canvas{
            height: 20vw;
            width: 24vw;
            //background-color: #fff;
          }
        }
        .item3{
          position: absolute;
          top: 9.8vw;
          right: 4.1vw;
          .canvas{
            height: 15vw;
            width: 24vw;
          //  background-color: #fff;
          }
        }
        .item4{
          position: absolute;
          top: 31vw;
          right: 4.1vw;
          .canvas{
            height: 20vw;
            width: 24vw;
           // background-color: #fff;
          }
        }
        .item5{
          position: absolute;
          top: 41.5vw;
          left: 30vw;
          .canvas{
            height: 11vw;
            width: 39vw;
           // background-color: #fff;
          }
        }
      }
    }
  }
}
</style>
